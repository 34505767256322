<template>
  <div style=" min-height:100vh; background: #FAF7F8;">
    <!-- header -->
    <div class="process">
      <div style="margin-top:4vw">我的</div>
    </div>
    <!-- user -->
    <div class="pay" style="display:flex;align-items: center; justify-content:center;flex-direction: column;position: relative">

      <img src="@/assets/image/moren.png" style="border-radius:50%;;width: 18vw;height: 18vw;border-radius: 9vw;background-size: 100% 100%;overflow: hidden;position: absolute;left: 37vw; top: -4vh;">

      <div style="font-size: 4vw;font-weight: 700;margin-top:2vw;" >你的名称:{{ username}}</div>
      <!-- <div style="font-size: 0.1vw;color: #ccc9cd;position: absolute;left: 38.5vw;top: 8.5vh;"> 你的职位</div> -->
    </div>
    <!-- list -->
<!-- <div class="pay" style="position: relative">

      <img src="@/assets/image/moren.png" style="border-radius:50%;position: absolute;left: 37vw; top: -4vh;width: 18vw;height: 18vw;border-radius: 9vw;background-size: 100% 100%;overflow: hidden;">

      <div style="font-size: 4vw;font-weight: 700;position: absolute;left: 37vw;top: 5.5vh;" >你的名称:{{ username}}</div>
     
    </div> -->
    <div
      style="display: flex; align-items: center; justify-content:center;margin-top: 3vh; padding:7vw 0;" class="my" >
      <div v-for="(item, index) in itemsList" :key="index" class="order_item" style="width: 23vw; font-size:2vw;margin-top:-3vw"  @click="changePasswordBtn(item.name)">
        <img :src="item.img" style="height: 5.6vw" />
        <div style="margin-top: 1vh; margin-bottom: 3vh">{{ item.name }}</div>
      </div>
    </div>
  
  
  
    <!-- 退出 -->
    <div class="exit" @click="loginPopup()">
      <div style="font-size:3vw;color:#898689;display:flex;align-items: center; justify-content:center; ">
        <img  src="../assets/tabbar/exit.png" alt="" style="width:3vw; height:3vw;padding-top:7vw;" />
        <div style="padding-top:6vw;" >退出登录</div>
      </div>
    </div>

    <!--底部tab栏-->
    <Tabbar2 :choose_index="2" :user_type2="user_type2" :type="pagetype"></Tabbar2>
     <!--修改密码弹窗-->
    <van-popup v-model="popup" round>
      <div v-if="popup_type==1" style="width:70vw;">
        <div style="text-align:center;margin:16px 0;font-size:18px;">修改密码</div>
        <div style="display:flex;justify-content:center;margin:20px 0;">
          <div style="font-size:15px;margin-right:10px;">原密码</div>
          <input type="password" v-model="password" class="search_input" placeholder="请输入" style="font-size:1vw;">
        </div>
        <div  style="display:flex;justify-content:center;">
          <div style="font-size:15px;margin-right:10px;" >新密码</div>
          <input type="password" v-model="newPassword" class="search_input" placeholder="请输入" style="font-size:1vw;">
        </div>
        <div style="display:flex;justify-content:center;margin:20px 0;">
          <van-button size="small" @click="popup=false">取消修改</van-button>
          <div style="width:20px;"></div>
          <van-button type="primary" size="small" @click="update_api()">确定修改</van-button>
        </div>
      </div>
      <!--退出登录-->
      <div v-if="popup_type==2" style="width:60vw;">
        <div style="text-align:center;margin:30px 0;font-size:16px;">确定退出登录?</div>
          <div style="display:flex;justify-content:center;margin:20px 0;">
          <van-button size="small" @click="popup=false">取消退出</van-button>
          <div style="width:20px;"></div>
          <van-button type="primary" size="small" @click="goLogin()">确定退出</van-button>
        </div>
      </div>

    </van-popup>
  </div>
</template>
<script>
import Tabbar2 from "@/components/Tabbar2";
export default {
  components: {
    Tabbar2,
  },
  data() {
    return {
      user_info:{},
      user_type2: false, //用户是否区域看板
      pagetype: 2,
      popup:false,//修改密码
      popup_type:1,//弹窗类型 1 修改密码 2退出登录
      password:'',//原密码
      newPassword:'',//新密码
      username:"",
      itemsList: [
        // {
        //   img: require("@/assets/image/center/xiaoxitixing.png"),
        //   name: "消息提醒",
        // },
        // {
        //   img: require("@/assets/image/center/kefu.png"),
        //   name: "联系客服",
        // },
        {
          img: require("@/assets/image/center/zhongzhimima.png"),
          name: "重置密码",
        },
        // {
        //   img: require("@/assets/image/center/fuwuneirong.png"),
        //   name: "我的内容",
        // },
        // {
        //   img: require("@/assets/image/center/zhanghaozhuxiao.png"),
        //   name: "账号注销",
        // },
        // {
        //   img: require("@/assets/image/center/yinsizhengce.png"),
        //   name: "隐私政策",
        // },
        // {
        //   img: require("@/assets/image/center/share.png"),
        //   name: "分享朋友",
        // },
        // {
        //   img: require("@/assets/image/center/fenxiaoshang.png"),
        //   name: "分销商",
        // },
      ],//my

    };
  },
  created() { 
    this.mingcheng()
   },
  methods: {
    mingcheng(){

       this.username=localStorage.getItem("mobile");
    }
    ,
    //退出登录提示
    loginPopup(){
      this.popup_type = 2;
      this.popup = true;
    },
   //退出登录
    goLogin(){
      localStorage.setItem("user_info",'');
      localStorage.setItem("token",'');
      this.$router.push('./login2');
      
    },
  //修改密码弹窗
  changePasswordBtn(name){
    if(name=="重置密码"){
      this.popup_type = 1;
      this.popup = true;
     };
    // if(name=="联系客服"){
    //    window.location.href=`https://kefu.boaiyiyao.com/im/text/104eac.html`
    //     //  window.location.href=`https://kefu.boaiyiyao.com/im/text/104eac.html?userid=${res.data.data.userId}`
    // }
  },
  //修改成功
    update_api(){
        this.showLoading();
        this.$axios.post(this.$api.update_api,{
          mobile:localStorage.getItem("mobile"),
          password:this.password,
          newPassword:this.newPassword,
        }).then(res=>{
          this.loading.close();
          if(res.data.code==0){
            this.$message({
              message:'修改成功',
              type: 'success'
            });
            this.password = '';
            this.newPassword = '';
            this.popup = false;
          }else{
            this.$message({
              message:res.data.msg,
              type: 'warning'
            });
          }
        })
    },
     //loading
    showLoading(){
       this.loading = this.$loading({
          lock: true,
          text: '查询中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
    },
  },
};
</script>
<style scoped>
.process {
  background-image: linear-gradient(#3f98eb, #006ed5);
  width: 100%;
  height: 8vh;
  color: #ffff;
  font-size: 4.5vw;
  font-weight: 300;
  display: flex;
  justify-content: center;
}

.my {
  width: 94vw;
  height: 24vw;
  border-radius: 16px;
  background-image: url("../assets/tabbar/2.png");
  background-size: 100% 100%;
  margin-left: 3vw;
  padding: 5vw 0;
 
}
.order_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  
}

.exit {
  width: 94vw;
  height: 20vw;
  border-radius: 16px;
  background-image: url("../assets/tabbar/1.png");
  background-size: 100% 100%;
  margin-left: 3vw;
  margin-top: 44vw;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.picture img {
  width: 20vw;
  height: 20vw;
  border-radius: 10vw;
}
.pay {
  width: 94vw;
  height: 24vw;
  border-radius: 16px;
  background-image: url("../assets/tabbar/1.png");
  background-size: 100% 100%;
  margin-left: 3vw;
  margin-top: 11vw;
}
</style>